import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-blog-starter/src/templates/single-post.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><strong parentName="p" {...{
        "className": "firstword"
      }}>{`Six Isms`}</strong>{` Or `}<strong parentName="p" {...{
        "className": "firstword"
      }}>{`الأسماء الستة`}</strong>{` are words in Arabic grammar having special conjugations in Raf, Nasb and Jar form.`}</p>
    <br />
    <p>{`Six Isms are as follows,`}</p>
    <ul>
      <li parentName="ul">{`أبوك`}</li>
      <li parentName="ul">{`أخوك`}</li>
      <li parentName="ul">{`حموك`}</li>
      <li parentName="ul">{`فوك`}</li>
      <li parentName="ul">{`ذو علم`}</li>
      <li parentName="ul">{`هنوك (A Thing)`}</li>
    </ul>
    <br />
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "650px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "39.877300613496935%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAICAYAAAD5nd/tAAAACXBIWXMAAAsTAAALEwEAmpwYAAABPklEQVQoz62QW0+DQBCF+f8/xahV47OpRuODtpq2RuMlWpDGggjCsrCX2eOwrSl98M1JJrPM+fbAIUCvnHO96dBqQpwp34tcI/psIRV5bZvdVNAX+t2VaB1GLxWG4xCnNxGun0oU8g9+fSfoG6xJ/2ytgZQNPtIC0SJFGCf+LISENWbLpP+C4HdhiaAZVNyybVEJwbPhnQaRZZhY02gUa1WFumnQMtvdMUSbyCZNkM+mqOIYmk3E0yPyh3uQUjDLJb6mE5gih2Utv5tBxe9wbFIwVzKnyhJ1x80m0IsYgWCz590dNAzY7wLh8RHS4QlcXaO8HWN+sA8VzWGSBK+DPWSXFwAnSM/PMD8cwGYZVDjHG2vV6AoBsUiiQjedtXwWIDZzHIM4FnE80tp/leekXGmy9qzfa7XSGrn5h/9VP14OaQw54dWDAAAAAElFTkSuQmCC')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Six Isms",
          "title": "Six Isms",
          "src": "/static/bd552bb30aad4cabd9a1b3ecd8db6261/a6d36/six-isms.png",
          "srcSet": ["/static/bd552bb30aad4cabd9a1b3ecd8db6261/222b7/six-isms.png 163w", "/static/bd552bb30aad4cabd9a1b3ecd8db6261/ff46a/six-isms.png 325w", "/static/bd552bb30aad4cabd9a1b3ecd8db6261/a6d36/six-isms.png 650w", "/static/bd552bb30aad4cabd9a1b3ecd8db6261/49217/six-isms.png 701w"],
          "sizes": "(max-width: 650px) 100vw, 650px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <h2>{`ما إعراب الأسماء الستة؟`}</h2>
    <ul>
      <li parentName="ul">{`ترفع بالواو`}</li>
      <li parentName="ul">{`تنصب بالألف`}</li>
      <li parentName="ul">{`تجر بالياء`}</li>
    </ul>
    <h2>{`ما شروط إعرابها؟`}</h2>
    <ul>
      <li parentName="ul"><strong parentName="li" {...{
          "className": "heading1"
        }}>{`أن تكون مفردة`}</strong>
        <ul parentName="li">
          <li parentName="ul">{`e.g. جاء أبوك`}</li>
          <li parentName="ul">{`Can't say `}<s>{`جاء الأبوان`}</s>{` OR `}<s>{`جاء الآباءُ`}</s></li>
        </ul>
      </li>
      <li parentName="ul"><strong parentName="li" {...{
          "className": "heading1"
        }}>{`أن تكون مضافة`}</strong>
        <ul parentName="li">
          <li parentName="ul">{`e.g. جاء أبو بكر`}</li>
          <li parentName="ul">{`Can't say `}<s>{`جاء أبٌ`}</s></li>
        </ul>
      </li>
      <li parentName="ul"><strong parentName="li" {...{
          "className": "heading1"
        }}>{`أن تكون مضافة لغير ياء المتكلم`}</strong>
        <ul parentName="li">
          <li parentName="ul">{`e.g. جاء أبو بكر OR جاء أبوك`}</li>
          <li parentName="ul">{`Can't say `}<s>{`جاء أبي`}</s></li>
        </ul>
      </li>
      <li parentName="ul"><strong parentName="li" {...{
          "className": "heading1"
        }}>{`أن تكون مكبرة (i.e. it should not be diminutive form)`}</strong>
        <ul parentName="li">
          <li parentName="ul">{`e.g. جاء أبو بكر OR جاء أبوك`}</li>
          <li parentName="ul">{`Can't say `}<s>{`جاء أُبَيّ`}</s></li>
        </ul>
      </li>
    </ul>
    <h2>{`Points to Remember`}</h2>
    <ul>
      <li parentName="ul">{`We have two ways to say mouth e.g. فو and فم. Remember, فم is not الأسماء الستة`}</li>
      <li parentName="ul">{`We see in lot of books الاسماء الخمسة not الأسماء الستة because `}
        <ul parentName="li">
          <li parentName="ul">{`هنوك is not used a lot`}</li>
          <li parentName="ul">{`Arabs used هنو with Damma, Fatha, Kasra not و ي ا `}</li>
        </ul>
      </li>
    </ul>
    <h2>{`Reference`}</h2>
    <p><a parentName="p" {...{
        "href": "https://www.qutoofacademy.com/",
        "rel": "nofollow noopener"
      }}>{`Qutoof Academy`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      